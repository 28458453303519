<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
    />
    <div class="todo-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              placeholder="Search task"
            />
          </b-input-group>
        </div>

        <!-- Dropdown -->
        <div class="dropdown">
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-asc' } }">
              Sort A-Z
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-desc' } }">
              Sort Z-A
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'assignee' } }">
              Sort Assignee
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'due-date' } }">
              Sort Due Date
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <!-- Todo List -->
      <div
        class="todo-task-list-wrapper list-group"
        cols="12"
      >
        <ul
          class="todo-task-list media-list">
          <li
            v-for="task in stats.actions"
            :key="task.id"
            class="todo-item"
            :class="{ 'completed': task.isCompleted }"
            @click="goto(task.id)"
          >
            <router-link :to="`/action/${task.id}`">
              <feather-icon
                icon="MoreVerticalIcon"
                class="draggable-task-handle d-inline"
              />
              <div class="todo-title-wrapper">
                <div class="todo-title-area">
                  <div class="title-wrapper">
                    <span class="todo-title"><b>{{ task.title }}</b> will solve {{ task.risks.length }} risks</span>
                  </div>
                </div>
                <div class="todo-item-action">
                  <div class="badge-wrapper mr-1">
                    <b-badge
                      v-for="tag in task.tags"
                      :key="tag"
                      pill
                      :variant="`light-${resolveTagVariant(tag)}`"
                      class="text-capitalize"
                    >
                      {{ tag }}
                    </b-badge>
                  </div>
                  <img
                    height="32"
                    style="float:right;"
                    :src="require(`@/assets/images/logos/${task.app_name}.webp`)"
                  >
                  <b-badge
                    :variant="`light-${getColor(task.priority)}`"
                    class="ml-1"
                  >
                    {{ task.priority }}
                  </b-badge>
                  <!-- <b-button>
                    Fix
                    </b-button> -->
                </div>
              </div>
              <!-- <div class="ml-4">
                <div class="border-0" v-for="scenario in task.scenarios" :key="scenario">- {{ scenario }}</div>
              </div> -->
            </router-link>
          </li>
        </ul>
        <div
          class="no-results"
          :class="{'show': !stats.actions || !stats.actions.length}"
        >
          <h5>No Items Found</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import {
  onUnmounted,
} from '@vue/composition-api'
import router from '@/router'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem,
  BBadge,
} from 'bootstrap-vue'
import useScenariosList from '../api/useScenariosList'
import scenarioStoreModule from '../api/scenarioStoreModule'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BBadge,
  },
  data() {
    return {
      stats: {},
      taskTags: [
        { title: 'Team', color: 'primary', route: { name: 'apps-todo-tag', params: { tag: 'team' } } },
        { title: 'Low', color: 'success', route: { name: 'apps-todo-tag', params: { tag: 'low' } } },
        { title: 'Medium', color: 'warning', route: { name: 'apps-todo-tag', params: { tag: 'medium' } } },
        { title: 'High', color: 'danger', route: { name: 'apps-todo-tag', params: { tag: 'high' } } },
        { title: 'Update', color: 'info', route: { name: 'apps-todo-tag', params: { tag: 'update' } } },
      ],
      searchQuery: '',
    }
  },
  mounted() {
    const stats = localStorage.getItem('stats')
    if (stats) {
      this.stats = JSON.parse(stats)
    }
    this.fetchStats(null, this.initActions)
  },
  methods: {
    initActions(stats) {
      this.stats = stats
      this.stats = JSON.parse(localStorage.getItem('stats'))
    },
    goto(taskId) {
      router.push(`/action/${taskId}`)
    },
  },
  setup() {
    const SCENARIO_APP_STORE_MODULE_NAME = 'app-scenario'

    // Register module
    if (!store.hasModule(SCENARIO_APP_STORE_MODULE_NAME)) store.registerModule(SCENARIO_APP_STORE_MODULE_NAME, scenarioStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SCENARIO_APP_STORE_MODULE_NAME)) store.unregisterModule(SCENARIO_APP_STORE_MODULE_NAME)
    })

    const {
      fetchStats,
    } = useScenariosList()

    return {
      fetchStats,
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: visible;
    }
}
</style>

<style lang="scss">
@import "@core/scss/base/pages/app-todo.scss";
</style>
